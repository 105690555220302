import React from 'react';
import { CashierPaymentsWidgetLazy } from '@wix/cashier-payments-widget/lazy';
import {
  ICashierPaymentsApi,
  ICashierPaymentsConfiguration,
} from '@wix/cashier-payments-widget';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { DeviceType } from '../../../../editor/types/common';

export interface ISingPaymentIntegrationProps {
  appId: string;
  userId: string;
  instanceId: string;
  deviceType: DeviceType;
  locale: string;
  siteOwnerId: string;
  orderId: string;
  amount?: string;
  currency?: string;
  onApiReady(api: ICashierPaymentsApi): void;
  paymentComplete(): void;
  onWidgetLoaded(): void;
  pendingOwnerApprove(): void;
  instance: string;
  host: IHostProps;
  msid: string;
  isMyWalletInstalled: boolean;
  domain: string;
}

interface IPaymentWidgetState {
  statusReported: boolean;
}

export class PaymentWidget extends React.Component<
  ISingPaymentIntegrationProps,
  IPaymentWidgetState
> {
  static displayName = 'CashierPaymentWidget';
  private widgetAPI: ICashierPaymentsApi;

  state = { statusReported: false };

  private readonly handleWidgetRef = async (ref) => {
    if (ref) {
      this.widgetAPI = await ref.getApi();
      this.props.onApiReady(this.widgetAPI);
      await this.widgetAPI.expand();
    }
  };

  private readonly handleLoadingUpdate = (subject, status) => {
    if (
      subject === 'CashierPaymentsWidget' &&
      status &&
      !this.state.statusReported
    ) {
      this.setState({
        statusReported: true,
      });
      this.props.onWidgetLoaded();
    }
  };

  render() {
    const configuration: ICashierPaymentsConfiguration = {
      appId: this.props.appId as any,
      appInstanceId: this.props.instanceId,
      locale: this.props.locale,
      visitorId: this.props.userId,
      snapshotId: this.props.orderId,
      siteOwnerId: this.props.siteOwnerId,
      viewMode: this.props.host.viewMode as any,
      isSignedInUser: true,
      appInstance: this.props.instance,
      msid: this.props.msid,
      amount: '0',
      currency: 'USD',
    };

    if (this.props.amount) {
      configuration.amount = this.props.amount;
    }

    if (this.props.currency) {
      configuration.currency = this.props.currency;
    }

    return (
      <CashierPaymentsWidgetLazy
        ref={this.handleWidgetRef}
        shouldApplySiteStyles
        debug={process.env.NODE_ENV === 'development'}
        allowManualPayment={true}
        siteStyles={this.props.host.style}
        onLoadingChanged={this.handleLoadingUpdate}
        onError={(...args) => console.info('onError', args)}
        onPaymentMethodsLoaded={(...args) =>
          console.info('onPaymentMethodsLoaded', args)
        }
        onWalletPaymentComplete={(...args) =>
          console.info('onWalletPaymentComplete', args)
        }
        onIsValidChanged={(...args) => console.info('onIsValidChanged', args)}
        onPaymentStart={(...args) => console.info('onPaymentStart', ...args)}
        onPaymentComplete={(paymentMethod, info) => {
          if (info.clientStatus === 'Pending') {
            this.props.pendingOwnerApprove();
          }
          if (info.clientStatus === 'Approved') {
            this.props.paymentComplete();
          }
        }}
        deviceType={this.props.deviceType}
        paymentMethodChanged={(...args) =>
          console.info('handlePaymentMethodChange', ...args)
        }
        configuration={configuration}
        isSaveCCEnabled={this.props.isMyWalletInstalled}
      />
    );
  }
}
