import React from 'react';
import { withProviders } from '../../../contexts/main/withProviders';
import { ISinglePaymentPageProps } from './interfaces';
import { PaymentWidgetContainer } from '../components/PaymentWidget/PaymentWidgetContainer';
import { PaymentCheckoutContainer } from '../components/PaymentCheckout/PaymentCheckoutContainer';
import { st, classes } from './Widget.st.css';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withUser } from '../../../contexts/User/withUser';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withChallengeData } from '../../../contexts/ChallengeDataProvider/withChallengeData';
import { applyProviders } from '../../../services/applyProviders';
import { withLoadingAPI } from '../../../contexts/Loading/LoadingContext';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const PaymentPage: React.FC<ISinglePaymentPageProps> = () => {
  const { isMobile, isRTL, isSSR } = useEnvironment();

  React.useEffect(() => {
    window?.scrollTo?.(0, 0);
  }, []);

  return (
    <main
      data-hook="challenges-payment-page"
      className={st(classes.root, {
        mobile: isMobile,
        rtl: isRTL,
      })}
    >
      <div className={classes.paymentWidgetWrapper}>
        {!isSSR && (
          <PaymentWidgetContainer
            onApiReady={() => {}}
            onWidgetLoaded={() => {}}
          />
        )}
      </div>

      <div className={classes.checkoutLayout}>
        <PaymentCheckoutContainer />
      </div>
    </main>
  );
};

export const PaymentPageWithProviders: React.FC<any> = applyProviders(
  PaymentPage,
  [withGeneralData, withLocation, withChallengeData, withLoadingAPI],
);

export default withProviders(
  withUser(withGeneralData(withLocation(withChallengeData(PaymentPage)))),
);
